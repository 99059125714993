// This file includes polyfills needed by Angular and is loaded before the app.
// You can add your own extra polyfills to this file.
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/set';
// import 'core-js/es6/reflect';

// import 'core-js/es7/reflect';
import 'zone.js/dist/zone';

// If you need to support the browsers/features below, uncomment the import
// and run `npm install import-name-here';
// Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html

// Needed for: IE9
// import 'classlist.js';

// Animations
// Needed for: All but Chrome and Firefox, Not supported in IE9
// import 'web-animations-js';

// Date, currency, decimal and percent pipes
// Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
// import 'intl';

// NgClass on SVG elements
// Needed for: IE10, IE11
// import 'classlist.js';
